import React from 'react';
import styled from 'styled-components';

import ButtonLink from "../components/button-link"
import Section from '../components/section';
import Layout from '../components/layout';
import Button from '../components/button';
import colors from '../shared/colors';

const StyledSection = styled(Section)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5em 0;
  min-height: 15em;
`

const Contact = () => (
  <Layout>
    <Section>
      <h2>Contact</h2>
    </Section>
    <StyledSection>
      <Button href="https://docs.google.com/forms/d/e/1FAIpQLSepF52aqKTvDgcF8CIukQgt1CMGTelAZristTYE4mZ19bF0xw/viewform?usp=sf_link" target="_blank" color={colors.transparent} border={colors.white}>Work with me</Button>
      <Button href="https://docs.google.com/forms/d/e/1FAIpQLSeiSOJUE4Fuyb81f71vXE6BQTKdRuUQWkrqFlaEFBxEtgn6dw/viewform?usp=sf_link" target="_blank" color={colors.transparent} border={colors.white}>Other inquires</Button>
    </StyledSection>
  </Layout>
)

export default Contact;