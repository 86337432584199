import React from "react";
import styled from "styled-components";

import watercolor from "../images/watercolor.jpg";
import OutboundLink from "./button-link";

const StyledButton = styled(OutboundLink)`
  position: relative;
  padding: 0 1rem;
  width: ${(props) => 0.8 * props.width}px;
  height: ${(props) => 0.7 * props.height}px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  margin: 0 0.8rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2), 0 3px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s cubic-bezier(0, 0.22, 0.3, 1);
  background: ${(props) => props.color};

  ${(props) => {
    if (props.border) {
      return `border: solid 2px ${props.border};`;
    }
    return "";
  }}

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.1);
  }

  span {
    color: #fff;
    font-size: 0.9rem;
    z-index: 10;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  & .btnBackground {
    left: -50%;
    top: -50%;
    background: ${(props) =>
      props.colorAlt || `url(${watercolor}) repeat 0% 0%`};
    background-size: cover;
    position: absolute;
    width: 0;
    height: 0;
    filter: url(#filter);
    border-radius: 50%;
    z-index: 5;
    transition: all 2.5s cubic-bezier(0.2, 0.82, 0.68, 0.67);
  }

  &:hover .btnBackground {
    width: ${(props) => 2 * props.width}px;
    height: ${(props) => 2 * props.height}px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Button = ({
  children,
  color,
  colorAlt,
  width = 200,
  height = 100,
  border,
  ...props
}) => (
  <Wrapper>
    <svg width="0" height="0">
      <filter id="filter">
        <feTurbulence type="fractalNoise" baseFrequency=".02" numOctaves="4" />
        <feDisplacementMap in="SourceGraphic" scale="100" />
      </filter>
    </svg>
    <StyledButton
      {...props}
      color={color}
      colorAlt={colorAlt}
      width={width}
      height={height}
      border={border}
    >
      <div className="btnBackground" />
      <span>{children}</span>
    </StyledButton>
  </Wrapper>
);

export default Button;
